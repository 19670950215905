import {
  AccessPoint_bool_exp,
  AccessPointType_enum,
  GrantableRoleFragment,
  order_by,
  Organization_bool_exp,
  PermissionKey_enum,
  PermissionScope_enum,
  Role_bool_exp,
  RoleKey_enum,
  Unit_bool_exp,
  usegetAccessPointsWithTypeQuery,
  usegetGrantableRolesQuery,
  usegetOrganizationsQuery,
  usegetUnitsAggregateQuery,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';

export interface IUseSelectRoleTableDataArgs {
  permissionScope?: PermissionScope_enum | null;
  scopedIds: string[];
  roleId?: string | null;
  setRole: (role: GrantableRoleFragment | null) => any;
}

export function useSelectRoleTableData(args: IUseSelectRoleTableDataArgs) {
  const { permissionScope, scopedIds, roleId, setRole } = args;

  let organizationBoolExp: Organization_bool_exp | undefined;
  let unitBoolExp: Unit_bool_exp | undefined;
  let accessPointBoolExp: AccessPoint_bool_exp | undefined;

  if (scopedIds.length) {
    if (permissionScope === PermissionScope_enum.ORGANIZATION) {
      organizationBoolExp = {
        organizationId: { _in: scopedIds },
      };
    } else if (permissionScope === PermissionScope_enum.PROPERTY) {
      organizationBoolExp = {
        properties: {
          propertyId: { _in: scopedIds },
        },
      };
    } else if (permissionScope === PermissionScope_enum.UNIT) {
      organizationBoolExp = {
        properties: {
          units: {
            unitId: { _in: scopedIds },
          },
        },
      };

      unitBoolExp = {
        unitId: { _in: scopedIds },
        sourceId: { _is_null: false },
      };
    } else if (permissionScope === PermissionScope_enum.ACCESS_POINT) {
      organizationBoolExp = {
        properties: {
          accessPoints: {
            accessPointId: { _in: scopedIds },
          },
        },
      };

      accessPointBoolExp = {
        accessPointId: { _in: scopedIds },
      };
    }
  }

  const unitsQueryResult = usegetUnitsAggregateQuery({
    skip: !unitBoolExp,
    fetchPolicy: 'cache-first',
    variables: {
      where: unitBoolExp as Unit_bool_exp,
    },
  });

  const controlledByPms = permissionScope === PermissionScope_enum.GLOBAL
    ? false // Prevent previous unit data from conflicting with global scope
    : (unitsQueryResult.data?.units.aggregate?.count || 0) > 0;

  const accessPointsQueryResult = usegetAccessPointsWithTypeQuery({
    skip: !accessPointBoolExp,
    fetchPolicy: 'cache-first',
    variables: {
      where: accessPointBoolExp as AccessPoint_bool_exp,
    },
  });

  const accessPoints = (accessPointsQueryResult.data?.accessPoints || []);
  const totalAmenities = accessPoints.filter(a => a.type === AccessPointType_enum.AMENITY).length;

  const allAmenities = (
    permissionScope === PermissionScope_enum.ACCESS_POINT &&
    totalAmenities === scopedIds.length
  );

  const someAmenities = (
    permissionScope === PermissionScope_enum.ACCESS_POINT &&
    totalAmenities > 0 &&
    totalAmenities < scopedIds.length
  );

  const someReservationOnly = !!accessPoints.length && accessPoints.some(a => a.type === AccessPointType_enum.RESERVATION_ONLY);

  const organizationsQueryResult = usegetOrganizationsQuery({
    skip: !organizationBoolExp,
    fetchPolicy: 'cache-first',
    variables: {
      where: organizationBoolExp as Organization_bool_exp,
    },
  });

  const organizationIds = permissionScope === PermissionScope_enum.GLOBAL
    ? [] // Prevent previous organization data from conflicting with global scope
    : organizationsQueryResult.data?.organizations.map(o => o.organizationId) || [];

  const roleOrBoolExpressions: Role_bool_exp[] = [{
    organizationId: { _is_null: true }, // Default roles
  }];

  if (organizationIds.length === 1) {
    roleOrBoolExpressions.push({
      // Custom roles for single organization
      organizationId: { _eq: organizationIds[0] },
    });
  }

  let roleAndBoolExpressions: Role_bool_exp[] = [{
    // Grantable roles based on current user's roles
    roleRelationshipsByRelatedRole: {
      roleId: {
        _in: authentication.currentDataScope.userRoles.map(u => u.role.roleId),
      },
      canGrantUserRole: { _eq: true },
    },
  }];

  if (
    permissionScope === PermissionScope_enum.GLOBAL &&
    authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeGlobal)
  ) {
    roleAndBoolExpressions = []; // Remove filters to allow selection of all global roles
  } else if (
    permissionScope !== PermissionScope_enum.GLOBAL &&
    authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped)
  ) {
    roleAndBoolExpressions = []; // Remove filters to allow selection of all scoped roles
  }

  if (permissionScope === PermissionScope_enum.ACCESS_POINT) {
    // Reservations should only be created via the app
    roleAndBoolExpressions.push({
      key: { _neq: RoleKey_enum.RESERVATION },
    });
  }

  const rolesQueryResult = usegetGrantableRolesQuery({
    fetchPolicy: 'cache-first',
    skip: (
      !authentication.currentDataScope.userRoles.length ||
      !permissionScope ||
      unitsQueryResult.loading ||
      organizationsQueryResult.loading
    ),
    variables: {
      where: {
        permissionScope: { _eq: permissionScope },
        _or: roleOrBoolExpressions,
        _and: roleAndBoolExpressions,
      },
      order_by: [
        { rolePermissions_aggregate: { count: order_by.desc } },
        { name: order_by.asc, },
      ],
    },
    onCompleted: ({ roles: queriedRoles }) => {
      if (queriedRoles.length === 1) {
        setRole(queriedRoles[0]); // Set default role
      }

      if (roleId && !queriedRoles.some(r => r.roleId === roleId)) {
        setRole(null); // Unselect role if not available within organization
      }
    },
  });

  const warnings: string[] = [];

  if (organizationIds.length > 1) {
    warnings.push(
      'Custom organization roles not available. ' +
      'Selected scope falls within multiple organizations.'
    );
  }

  let roles = rolesQueryResult.data?.roles || [];

  if (controlledByPms) {
    roles = roles.filter(r => r.key !== RoleKey_enum.RESIDENT);
  }

  if (someReservationOnly) {
    roles = [];
  } else if (!allAmenities) {
    // Cannot have a mix of amenity access
    roles = roles.filter(r => r.key !== RoleKey_enum.AMENITY_ACCESS);
  }

  if (controlledByPms) {
    warnings.push(
      'Resident role not available. ' +
      'One or more selected units are controlled by Property Management System.'
    );
  }

  if (someReservationOnly) {
    warnings.push(
      'One or more selected access points are Reservation Only. ' +
      'Reservations can only be made through the Chirp mobile app.'
    );
  }

  if (someAmenities) {
    warnings.push(
      'Amenity Access role not available. ' +
      'Selected access points include a mix of amenities and non-amenities.'
    );
  }

  return {
    roles,
    warnings,
    loading: (
      unitsQueryResult.loading ||
      organizationsQueryResult.loading ||
      rolesQueryResult.loading
    ),
  };
}

export default useSelectRoleTableData;
