import { enumManagers } from '@chirp/enums';
import { Descriptions } from 'antd';
import React from 'react';

import {
  UserRoleActionFragmentDoc,
  UserRoleExpandedFragment,
} from '../../../graphql/hasura/generated';
import { formatTimestamp } from '../../../utils';
import { IModelTableExpandable } from '../../typings';
import UserModel from '../../User/model';
import * as actions from '../actions';
import model from '../model';

model.actions.setDefaultActions([
  actions.revokeAction,
], UserRoleActionFragmentDoc);

const { Item } = Descriptions;

export const expandable: IModelTableExpandable<UserRoleExpandedFragment> = {
  rowExpandable: ({ sourceId, grantMethod, reason, notes, revokedAt }) => (
    !!(sourceId || grantMethod || reason || notes || revokedAt)
  ),
  expandedRowRender: (userRole) => {
    const { sourceId, grantMethod, reason, notes, revokedAt, revokedByUser } = userRole;

    return (
      <Descriptions column={1} style={{ width: '100%' }}>
        {sourceId && <Item span={1} label='Source ID'>{sourceId}</Item>}
        {grantMethod && (
          <Item span={1} label='Grant Method'>
            {enumManagers.UserRoleGrantMethod.getLabel(grantMethod)}
          </Item>
        )}
        {reason && (
          <Item span={1} label='Reason for Granting'>{enumManagers.UserRoleReason.getLabel(reason)}</Item>
        )}
        {notes && <Item span={2} label='Notes'>{notes}</Item>}
        {revokedAt && <Item span={1} label='Revoked At'>{formatTimestamp(revokedAt)}</Item>}
        {revokedByUser && (
          <Item span={1} label='Revoked By'>{UserModel.routes.renderRowLink(revokedByUser)}</Item>
        )}
      </Descriptions>
    );
  },
};

export const commonTableConfig = { expandable };
