import { Alert, Card, Table } from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import RefreshButton from '../../../components/RefreshButton';
import { apiClient } from '../../../graphql/api/apiClient';
import { useaugustLocksWithPinCodesQuery } from '../../../graphql/api/generated';
import {
  AugustPinCodeUserRoleFragment,
  RoleKey_enum,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import {
  buildLockResetUnitKeyUrl,
  buildLockSyncUnitKeyUrl,
} from '../../../pages/GrantAccess/buildGrantAccessPageUrl';
import { displayErrorMessage } from '../../../utils';

import { createSmartLockPinCodeColumns, IPinCodeRow } from './createSmartLockPinCodeColumns';

const BRIDGE_OFFLINE_WINDOW_MS = 5 * 60 * 1000; // 5 minutes

interface ISmartLockPinCodeTableProps {
  userRoles: AugustPinCodeUserRoleFragment[];
}

export const SmartLockPinCodeTable: React.FC<ISmartLockPinCodeTableProps> = (props) => {
  const { userRoles } = props;

  const augustLockIds = new Set<string>();
  const lockResetUnitIds = new Set<string>();
  const syncDataUnitIds = new Set<string>();

  for (const { scopedSmartLock } of userRoles) {
    if (scopedSmartLock) {
      if (scopedSmartLock.openLockResetTickets.aggregate?.count) {
        lockResetUnitIds.add(scopedSmartLock.unit.unitId);
      }

      augustLockIds.add(scopedSmartLock.sourceId);
    }
  }

  const { data, loading, refetch, startPolling, stopPolling } = useaugustLocksWithPinCodesQuery({
    variables: { augustLockIds: [...augustLockIds] },
    skip: !augustLockIds.size,
    client: apiClient,
    fetchPolicy: 'network-only',
    onError: error => displayErrorMessage(error),
  });

  const augustLocks = data?.augustLocks || [];
  const pinCodeRows: IPinCodeRow[] = [];

  for (const userRole of userRoles) {
    const { userRoleId, status, role, scopedSmartLock, expiresAt, revokedAt, createdAt } = userRole;

    if (role.key !== RoleKey_enum.SMART_LOCK_UNREGISTERED_USER || !scopedSmartLock) {
      continue; // @TODO: Support other roles?
    }

    const augustLock = augustLocks.find(l => l.LockID === scopedSmartLock.sourceId);

    if (!augustLock) {
      continue; // Should not happen
    }

    const pinCode = augustLock.pinCodes.find(p => p.partnerUserID && p.partnerUserID === userRoleId);
    const currentlyOnline = augustLock.Bridge?.status.current === 'online';

    let stale = false;

    if (status === UserRoleStatus_enum.ACTIVE || status === UserRoleStatus_enum.PENDING) {
      const windowElapsed = moment().diff(moment(new Date(createdAt))) >= BRIDGE_OFFLINE_WINDOW_MS;

      stale = !pinCode || (pinCode.status === 'created' && currentlyOnline && !windowElapsed);
    } else if (status === UserRoleStatus_enum.EXPIRED || status === UserRoleStatus_enum.REVOKED) {
      const endDate = revokedAt || expiresAt;
      const windowElapsed = !endDate || moment().diff(moment(new Date(endDate))) >= BRIDGE_OFFLINE_WINDOW_MS;

      stale = currentlyOnline
        ? (!!pinCode && !windowElapsed)
        : (!!pinCode && pinCode.status !== 'deleting');
    }

    if (pinCode?.status === 'created' && !stale) {
      syncDataUnitIds.add(scopedSmartLock.unit.unitId);
    }

    pinCodeRows.push({ pinCode, userRole, stale, Bridge: augustLock.Bridge });
  }

  const sortedPinCodeRows = orderBy(pinCodeRows, [r => r.userRole.scopedSmartLock?.unit.unitNumber], ['asc']);
  const shouldPoll = pinCodeRows.some(r => r.stale);

  useEffect(() => {
    if (shouldPoll) {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [shouldPoll]);

  useEffect(() => {
    if (!shouldPoll) {
      // Update pin code statuses if lock reset tickets are solved
      refetch();
    }
  }, [lockResetUnitIds.size]);

  return (
    <Card
      title='Smart Lock Pin Codes'
      bordered={false}
      style={{ marginBottom: 20 }}
      extra={(
        <RefreshButton
          disabled={loading || !sortedPinCodeRows.length}
          refresh={refetch}
          useSubscription={false}
        />
      )}
    >
      {!!syncDataUnitIds.size && (
        <Alert
          showIcon
          type='warning'
          message={
            <>
              {syncDataUnitIds.size} {pluralize('unit', syncDataUnitIds.size)} with pin codes to load.&nbsp;
              <Link to={buildLockSyncUnitKeyUrl([...syncDataUnitIds])}>
                Create Unit {pluralize('Key', syncDataUnitIds.size)}
              </Link>
            </>
          }
          style={{ marginBottom: 25 }}
        />
      )}
      {!!lockResetUnitIds.size && (
        <Alert
          showIcon
          type='error'
          message={
            <>
              {lockResetUnitIds.size} {pluralize('unit', lockResetUnitIds.size)} with open lock reset ticket.&nbsp;
              <Link to={buildLockResetUnitKeyUrl([...lockResetUnitIds])}>
                Create Unit {pluralize('Key', lockResetUnitIds.size)}
              </Link>
            </>
          }
          style={{ marginBottom: 25 }}
        />
      )}
      <Table
        columns={createSmartLockPinCodeColumns()}
        dataSource={sortedPinCodeRows}
        rowKey={r => r.userRole.userRoleId}
        loading={loading}
        pagination={{
          pageSize: 5,
          hideOnSinglePage: true,
        }}
      />
    </Card>
  );
};

export default SmartLockPinCodeTable;
