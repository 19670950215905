import { Alert } from 'antd';
import React from 'react';

import { AllowedPermissionFragment, PermissionKey_enum } from '../../../graphql/hasura/generated';
import { generateWordsFromSearch } from '../../../utils';

import { ICategorizedPermission, IPermissionCategory, PermissionFilter } from './typings';

const Warning: React.FC<{ message: string }> = props => {
  return (
    <Alert showIcon type='warning' message={props.message} />
  );
}

export function getPermissionCategories(): IPermissionCategory[] {
  return [
    {
      categoryName: 'Applications',
      permissions: [
        {
          name: 'Chirp Admin Panel',
          key: PermissionKey_enum.Session_AdminPanel,
          description: 'Web application used for access and user management',
        },
        {
          name: 'Chirp Access App',
          key: PermissionKey_enum.Session_AccessApp,
          description: 'Mobile application used to unlock access points and door locks, manage visitors, and install Chirp access control',
        },
      ],
    },
    {
      categoryName: 'Dashboard',
      permissions: [
        {
          name: 'User Metrics',
          key: PermissionKey_enum.User_Metrics,
          dependencies: [PermissionKey_enum.Session_AdminPanel],
          description: 'View metrics on mobile app usage',
        },
        {
          name: 'August SDK Fees Report',
          key: PermissionKey_enum.SmartLock_AugustSdkFeesReport,
          dependencies: [PermissionKey_enum.Session_AdminPanel, PermissionKey_enum.SmartLock_Read],
          description: 'View monthly August SDK fees based on total active locks',
        },
      ],
    },
    {
      categoryName: 'Organizations',
      permissions: [
        {
          name: 'Create',
          key: PermissionKey_enum.Organization_Create,
          description: 'Create new organizations',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Organization_Update,
          description: 'Edit organization information',
        },
      ],
    },
    {
      categoryName: 'Properties',
      permissions: [
        {
          name: 'Create',
          key: PermissionKey_enum.Property_Create,
          description: 'Create new properties',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Property_Update,
          description: 'Edit property information',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Property_Delete,
          description: 'Delete properties',
        },
        {
          name: 'Add/Remove Feature Flags',
          key: PermissionKey_enum.Property_ManageFeatureFlags,
          description: 'Add or remove flags to toggle property features',
        },
        {
          name: 'PMS: Audit Residents',
          key: PermissionKey_enum.Property_PmsResidentAudit,
          description: 'Audit all current residents synced by the property management system and determine eligibility status',
        },
        {
          name: 'PMS: Sync Units',
          key: PermissionKey_enum.Property_PmsUnitSync,
          description: 'Synchronize units from a property management system',
        },
        {
          name: 'PMS: Sync Residents',
          key: PermissionKey_enum.Property_PmsResidentSync,
          dependencies: [PermissionKey_enum.Property_PmsResidentAudit],
          description: 'Trigger on-demand sync with PMS for entire property',
          extra: <Warning message='Reserved for support roles' />,
        },
        {
          name: 'Manage Maps',
          key: PermissionKey_enum.Property_ManageMaps,
          dependencies: [PermissionKey_enum.Property_Update],
          description: 'Manage property maps used for guest access and Self-Guided Tours',
        },
      ],
    },
    {
      categoryName: 'Units',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Unit_Read,
          description: 'View unit information',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.Unit_Create,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Create new units',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Unit_Update,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Edit unit information',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Unit_Delete,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Delete units',
        },
        {
          name: 'PMS: Audit Residents',
          key: PermissionKey_enum.Unit_PmsResidentAudit,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'View unit information synced by PMS',
        },
        {
          name: 'PMS: Sync Residents',
          key: PermissionKey_enum.Unit_PmsResidentSync,
          dependencies: [PermissionKey_enum.Unit_PmsResidentAudit],
          description: 'Trigger on-demand sync with PMS',
        },
      ],
    },
    {
      categoryName: 'Unit Groups',
      permissions: [
        {
          name: 'Create',
          key: PermissionKey_enum.UnitGroup_Create,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Create new unit groups',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.UnitGroup_Update,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Edit unit group information',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.UnitGroup_Delete,
          dependencies: [PermissionKey_enum.Unit_Read],
          description: 'Delete unit groups',
        },
      ],
    },
    {
      categoryName: 'Access Points',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.AccessPoint_Read,
          description: 'View access point information',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.AccessPoint_Create,
          dependencies: [PermissionKey_enum.AccessPoint_Read],
          description: 'Create access points',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.AccessPoint_Update,
          dependencies: [PermissionKey_enum.AccessPoint_Read],
          description: 'Edit access point information',
        },
        {
          name: 'Archive',
          key: PermissionKey_enum.AccessPoint_Archive,
          dependencies: [PermissionKey_enum.AccessPoint_Read],
          description: 'Archive access points',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.AccessPoint_Delete,
          dependencies: [PermissionKey_enum.AccessPoint_Read],
          description: 'Delete access points',
        },
        {
          name: 'Add/Remove Relays',
          key: PermissionKey_enum.AccessPoint_ManageRelays,
          dependencies: [PermissionKey_enum.AccessPoint_Read],
          description: 'Add or remove relays assigned to an access point',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Unlock',
          key: PermissionKey_enum.AccessPoint_Unlock,
          description: 'Unlock an access point via Chirp Access App, Chirp Admin Panel, or valid credential',
        },
        {
          name: 'Unlock: Staff Access',
          key: PermissionKey_enum.AccessPoint_StaffAccess,
          description: 'Unlock ANY access point within scope of role (i.e., automatically whitelisted)',
        },
        {
          name: 'Unlock: Bypass Schedule',
          key: PermissionKey_enum.AccessPoint_BypassSchedule,
          description: 'Unlock access points outside of normal business hours',
        },
        // @TODO: Remove this permission from the database
        // {
        //   name: 'Unlock: Bypass Reservation Only',
        //   key: PermissionKey_enum.AccessPoint_BypassReservationOnly,
        //   description: 'Bypass the need to create a reservation for reservation-only access points',
        //   extra: <Warning message='Contact Chirp to enable/disable' />,
        // },
      ],
    },
    {
      categoryName: 'Users',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.User_Read,
          description: 'View ALL users and their assigned roles',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.User_Create,
          description: 'Create new users',
        },
        {
          name: 'Update Profile',
          key: PermissionKey_enum.User_UpdateProfile,
          description: 'Update user information (name, phone number, email)',
        },
        {
          name: 'Update Account Status',
          key: PermissionKey_enum.User_UpdateAccountStatus,
          description: `Enable or Disable a user's account`,
        },
        {
          name: 'Send Message',
          key: PermissionKey_enum.User_SendMessage,
          description: 'Send a text message to a user with a link to download the Chirp app in the App Store or Google Play Store',
        },
      ],
    },
    {
      categoryName: 'Roles',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Role_Read,
          description: 'View roles',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.Role_Create,
          description: 'Create new roles',
          dependencies: [PermissionKey_enum.Role_Read],
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Role_Update,
          dependencies: [PermissionKey_enum.Role_Read],
          description: 'Edit role information (name, description, etc.)',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Role_Delete,
          dependencies: [PermissionKey_enum.Role_Read],
          description: 'Delete roles',
        },
        {
          name: 'Manage Permissions',
          key: PermissionKey_enum.Role_ManagePermissions,
          dependencies: [PermissionKey_enum.Role_Read],
          description: 'Manage permissions for roles',
        },
        {
          name: 'Manage (Unrestricted)',
          key: PermissionKey_enum.Role_ManageUnrestricted,
          dependencies: [
            PermissionKey_enum.Role_Read,
            PermissionKey_enum.Role_Create,
            PermissionKey_enum.Role_Delete,
            PermissionKey_enum.Role_Create,
            PermissionKey_enum.Role_ManagePermissions,
          ],
          description: 'Create/edit/delete global roles and manage locked permissions',
          extra: <Warning message='Reserved for Super Admins' />,
        },
      ],
    },
    {
      categoryName: 'Assigned Roles',
      permissions: [
        {
          name: 'Grant/Revoke (Scoped)',
          key: PermissionKey_enum.UserRole_GrantRevokeScoped,
          dependencies: [PermissionKey_enum.User_Read],
          description: 'Grant/revoke ANY scoped role (organization, property, unit, etc.)',
        },
        {
          name: 'Grant/Revoke (Global)',
          key: PermissionKey_enum.UserRole_GrantRevokeGlobal,
          dependencies: [PermissionKey_enum.UserRole_GrantRevokeScoped],
          description: 'Grant/revoke ANY global role',
          extra: <Warning message='Reserved for Super Admins' />,
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.UserRole_Update,
          description: 'Manually edit assigned roles',
          extra: <Warning message='Reserved for Super Admins' />,
        },
      ],
    },
    {
      // @TODO: Reevaluate these permissions
      categoryName: 'Credentials',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Credential_Read,
          description: 'View credentials',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.Credential_Create,
          dependencies: [PermissionKey_enum.Credential_Read],
          description: 'Create new credentials (e.g., assigning fobs)',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Credential_Update,
          dependencies: [PermissionKey_enum.Credential_Read],
          description: 'Edit credentials (i.e., reassigning fobs)',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Credential_Delete,
          dependencies: [PermissionKey_enum.Credential_Read],
          description: 'Delete credentials',
        },
      ],
    },
    {
      categoryName: 'Pin Codes',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.PinCode_Read,
          description: 'View users with pin codes',
        },
        {
          name: 'Read: Code Visible',
          key: PermissionKey_enum.PinCodePrivate_Read,
          dependencies: [PermissionKey_enum.PinCode_Read],
          description: 'View the pin code digits',
        },
        {
          name: 'Generate Random',
          key: PermissionKey_enum.PinCode_GenerateRandomByUser,
          dependencies: [PermissionKey_enum.PinCode_Read],
          description: 'Randomly generate a pin code for a user',
        },
        // @TODO: Remove this permission from the database (not currently used for any API calls)
        // {
        //   name: 'Generate Random (Entire Property)',
        //   key: PermissionKey_enum.PinCode_GenerateRandomByProperty,
        //   description: 'Randomly generate pin codes for the entire property',
        // },
        {
          name: 'Create',
          key: PermissionKey_enum.PinCode_Create,
          dependencies: [PermissionKey_enum.PinCode_Read],
          description: 'Manually create new pin codes',
          extra: <Warning message='Reserved for support roles' />,
        },
        {
          name: 'Update',
          key: PermissionKey_enum.PinCode_Update,
          dependencies: [PermissionKey_enum.PinCode_Read],
          description: 'Manually edit pin code information',
          extra: <Warning message='Reserved for support roles' />,
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.PinCode_Delete,
          dependencies: [PermissionKey_enum.PinCode_Read],
          description: 'Delete pin codes',
        },
        {
          name: 'Auto-Receive',
          key: PermissionKey_enum.PinCode_AutoReceive,
          description: 'Automatically receive a randomly generated pin code (if applicable)',
        },
      ],
    },
    {
      categoryName: 'Sessions',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Session_Read,
          description: 'View sessions',
        },
        {
          name: 'Revoke',
          key: PermissionKey_enum.Session_Revoke,
          dependencies: [PermissionKey_enum.Session_Read],
          description: 'Remotely log the user out of the Chirp Access App or Chirp Admin Panel',
        },
      ],
    },
    {
      categoryName: 'Hubs',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Hub_Read,
          description: 'View hub information',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Hub_Update,
          dependencies: [PermissionKey_enum.Hub_Read],
          description: 'Edit hub information',
          extra: <Warning message='Reserved for support roles and certified integrators' />,
        },
        {
          name: 'Restart',
          key: PermissionKey_enum.Hub_Restart,
          dependencies: [PermissionKey_enum.Hub_Read],
          description: 'Restart (online) hubs remotely',
        },
        {
          name: 'Manage Readers',
          key: PermissionKey_enum.Hub_ManageReaders,
          dependencies: [PermissionKey_enum.Hub_Read],
          description: 'Monitor readers and adjust reader power',
        },
        {
          name: 'Gateway Installation',
          key: PermissionKey_enum.Hub_Installation,
          dependencies: [PermissionKey_enum.Hub_Read],
          description: 'Manage Gateway in the Chirp Access App (hub, relays, readers, beacons, etc.)',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Messenger',
          key: PermissionKey_enum.Hub_Messenger,
          dependencies: [PermissionKey_enum.Hub_Read],
          description: 'Ping hub, restart hub, monitor DPS, monitor readers, adjust reader power, etc.',
          extra: <Warning message='Reserved for support roles' />,
        },
      ],
    },
    {
      categoryName: 'Relays',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Relay_Read,
          description: 'View relay information',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.Relay_Create,
          dependencies: [PermissionKey_enum.Relay_Read],
          description: 'Create new relays',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Relay_Update,
          dependencies: [PermissionKey_enum.Relay_Read],
          description: 'Edit relay information',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Relay_Delete,
          dependencies: [PermissionKey_enum.Relay_Read],
          description: 'Delete relays',
          extra: <Warning message='Reserved for certified integrators' />,
        },
      ],
    },
    // @TODO: Reevaluate these permissions and dependencies
    {
      categoryName: 'Beacons',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Beacon_Read,
          description: 'View beacon information',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.Beacon_Create,
          dependencies: [PermissionKey_enum.Beacon_Read],
          description: 'Create new beacons',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.Beacon_Update,
          dependencies: [PermissionKey_enum.Beacon_Read],
          description: 'Edit beacon information',
          extra: <Warning message='Reserved for certified integrators' />,
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Beacon_Delete,
          dependencies: [PermissionKey_enum.Beacon_Read],
          description: 'Delete beacons',
          extra: <Warning message='Reserved for certified integrators' />,
        },
      ],
    },
    {
      categoryName: 'Smart Locks',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.SmartLock_Read,
          description: 'View smart lock information',
        },
        {
          name: 'Create',
          key: PermissionKey_enum.SmartLock_Create,
          dependencies: [
            PermissionKey_enum.Session_AccessApp,
            PermissionKey_enum.SmartLock_Read,
          ],
          description: 'Provision new smart locks via the Chirp Access App',
        },
        {
          name: 'Edit',
          key: PermissionKey_enum.SmartLock_Update,
          dependencies: [PermissionKey_enum.SmartLock_Read],
          description: 'Edit smart lock information',
        },
        {
          name: 'Factory Reset',
          key: PermissionKey_enum.SmartLock_FactoryReset,
          dependencies: [PermissionKey_enum.SmartLock_Read],
          description: 'Factory reset smart locks (without deleting)',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.SmartLock_Delete,
          dependencies: [PermissionKey_enum.SmartLock_Read],
          description: 'Delete smart locks',
        },
        {
          name: 'Unlock',
          key: PermissionKey_enum.SmartLock_Unlock,
          description: 'Unlock smart locks associated to the unit',
        },
        {
          name: 'Auto-Receive Pin Code',
          key: PermissionKey_enum.SmartLock_AutoReceivePinCode,
          description: 'Automatically receive a randomly generated pin code',
        },
        {
          name: 'Smart Locks Report',
          key: PermissionKey_enum.SmartLock_PropertyReport,
          dependencies: [PermissionKey_enum.SmartLock_Read],
          description: 'Generate smart locks report for battery levels and firmware information',
        },
      ],
    },
    {
      categoryName: 'Gateway Logs',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.RelayLog_Read,
          description: 'View gateway logs without user information',
        },
        {
          name: 'Read: User Visible',
          key: PermissionKey_enum.RelayLogPrivate_Read,
          dependencies: [PermissionKey_enum.RelayLog_Read],
          description: 'View gateway logs with associated user',
        },
      ],
    },
    {
      categoryName: 'Home Logs',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.SmartLockLog_Read,
          description: 'View home logs for other users',
        },
      ],
    },
    {
      categoryName: 'Tickets',
      permissions: [
        {
          name: 'Read',
          key: PermissionKey_enum.Ticket_Read,
          description: 'View tickets',
        },
        // Let's hide this permission for now since we only enable the "New Ticket" screen for development
        // {
        //   name: 'Create',
        //   key: PermissionKey_enum.Ticket_Create,
        //   dependencies: [PermissionKey_enum.Ticket_Read],
        //   description: 'Create new tickets',
        // },
        {
          name: 'Edit Status',
          // @TODO: Change to Ticket_UpdateStatus
          key: PermissionKey_enum.Ticket_Update,
          dependencies: [PermissionKey_enum.Ticket_Read],
          description: 'Edit ticket status',
        },
        {
          name: 'Delete',
          key: PermissionKey_enum.Ticket_Delete,
          dependencies: [PermissionKey_enum.Ticket_Read],
          description: 'Delete tickets',
        },
      ],
    },
  ];
};

export function flattenPermissionCategories(
  permissionCategories: IPermissionCategory[],
  allowedPermissions: AllowedPermissionFragment[],
): ICategorizedPermission[] {
  const categorizedPermissions: ICategorizedPermission[] = [];

  for (const permissionCategory of permissionCategories) {
    for (const permission of permissionCategory.permissions) {
      const allowedPermission = allowedPermissions.find(p => p.key === permission.key);

      if (!allowedPermission) {
        continue;
      }

      categorizedPermissions.push({
        permissionId: allowedPermission.permissionId,
        isLocked: allowedPermission.isLocked,
        categoryName: permissionCategory.categoryName,
        ...permission,
      });
    }
  }

  return categorizedPermissions;
}

interface IFilterPermissionCategoriesArgs {
  categorizedPermissions: ICategorizedPermission[];
  enabledPermissionIds: string[];
  permissionFilter: PermissionFilter;
  search?: string | null;
}

export function filterCategorizedPermissions(
  args: IFilterPermissionCategoriesArgs,
): ICategorizedPermission[] {
  const {
    categorizedPermissions, enabledPermissionIds, permissionFilter, search,
  } = args;

  const searchWords = search ? generateWordsFromSearch(search.toLowerCase()) : [];

  const filteredPermissions = categorizedPermissions.filter(p => {
    if (
      permissionFilter === PermissionFilter.SHOW_ENABLED &&
      !enabledPermissionIds.includes(p.permissionId)
    ) {
      return false;
    }

    if (
      permissionFilter === PermissionFilter.SHOW_DISABLED &&
      enabledPermissionIds.includes(p.permissionId)
    ) {
      return false;
    }

    if (
      searchWords.length &&
      !(searchWords.every(word => (
        p.categoryName.toLowerCase().includes(word) ||
        p.name.toLowerCase().includes(word) ||
        p.description.toLowerCase().includes(word)
      )))
    ) {
      return false;
    }

    return true;
  });

  return filteredPermissions;
}
