import { PinCode_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const PinCodeModel = new Model<PinCode_bool_exp>({
  names: {
    schemaName: 'PinCode',
    displayName: 'Property Pin Code',
    pluralDisplayName: 'Property Pin Codes',
  },

  permissionsOptions: {
    canCreate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canCreate(),
    canUpdate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canUpdate(),
    canDelete: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canDelete(),
  },
});

export default PinCodeModel;
