import { Col, Row, Typography } from 'antd';
import React, { useContext } from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleReason_enum,
} from '../../graphql/hasura/generated';
import { ModelsContext } from '../../hooks/providers';
import { EnumFormSelect, TextAreaField } from '../ModelForm/form-fields';

import { getFieldRequirements, IGrantAccessPageMergedProps } from './formik';

export const AdditionalContextSection = (props: IGrantAccessPageMergedProps) => {
  const { setFieldValue, values: formikValues } = props;
  const { permissionScope, role, scopedIds } = formikValues;

  const { getModel } = useContext(ModelsContext);
  const UserRoleModel = getModel('UserRole');

  const { reasonToGrantRequired } = getFieldRequirements(role);

  // Only display for property/unit roles without admin panel access
  const reasonToGrantVisible = (
    (
      permissionScope === PermissionScope_enum.PROPERTY ||
      permissionScope === PermissionScope_enum.UNIT
    ) &&
    role &&
    role.key !== RoleKey_enum.RESIDENT &&
    !role.rolePermissions.some(rp => (
      rp.permission.key === PermissionKey_enum.Session_AdminPanel
    ))
  );

  const shouldDisplay = (
    !!scopedIds.length && (reasonToGrantVisible || reasonToGrantRequired)
  );

  if (!shouldDisplay) {
    return null;
  }

  return (
    <section style={{ overflow: 'hidden', marginBottom: 20 }}>
      <Typography.Title level={4}>
        Additional context
      </Typography.Title>

      <Typography.Paragraph>
        If necessary, select a reason for granting this user access.
        Notes will be visible to other admin panel users.
      </Typography.Paragraph>

      <Row justify='space-between' gutter={.5}>
        <Col span={24}>
          <EnumFormSelect
            enumSelectProps={{
              enumTable: 'UserRoleReason',
              value: formikValues.reason,
              onChange: (nextReason) => setFieldValue('reason', nextReason),
              filterOptions: reasons => {
                if (role?.key === RoleKey_enum.INTEGRATOR_GATEWAY) {
                  return reasons.filter(r => (
                    r.value === UserRoleReason_enum.GATEWAY_INSTALLATION ||
                    r.value === UserRoleReason_enum.GATEWAY_SERVICE_CALL
                  ));
                }

                let filteredReasons = reasons;

                if (role?.key !== RoleKey_enum.STAFF_UNIT_KEY) {
                  filteredReasons = filteredReasons.filter(r => (
                    !([
                      UserRoleReason_enum.SMART_LOCK_SYNC,
                      UserRoleReason_enum.SMART_LOCK_LOW_BATTERY,
                      UserRoleReason_enum.SMART_LOCK_PROVISIONING,
                      UserRoleReason_enum.SMART_LOCK_TROUBLESHOOTING,
                      UserRoleReason_enum.TURNOVER_LOCK_RESET,
                    ] as string[]).includes(r.value)
                  ));
                }

                return filteredReasons.filter(r => (
                  r.value !== UserRoleReason_enum.GATEWAY_INSTALLATION &&
                  r.value !== UserRoleReason_enum.GATEWAY_SERVICE_CALL
                ));
              },
              allowClear: true,
              style: { width: '100%' },
            }}
            formItemProps={{
              ...props,
              label: `Reason${reasonToGrantRequired ? '' : ' (Optional)'}`,
              className: 'label-align-top',
              formItemLayout: {},
              required: reasonToGrantRequired,
              field: UserRoleModel.introspection.getField('reason'),
              isNew: false,
              handleChange: () => {},
            }}
          />
        </Col>
      </Row>

      <TextAreaField
        {...props}
        label='Notes (Optional)'
        className='label-align-top'
        formItemLayout={{}}
        isNew={false}
        field={UserRoleModel.introspection.getField('notes')}
        maxLength={1500} // @TODO: Get maxLength from form options
      />
    </section>
  );
};

export default AdditionalContextSection;
