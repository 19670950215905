import React from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleDetailsFragment,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { SmartLockModel } from '../../SmartLock/model';
import { UnitModel } from '../../Unit/model';

import { getInvitationAccess } from './send-invitation-formik';
import SendInvitationForm from './SendInvitationForm';
import SmartLockPinCodeTable from './SmartLockPinCodeTable';

interface IUserRolePinCodeManagerProps {
  userRole: UserRoleDetailsFragment;
}

export const UserRolePinCodeManager: React.FC<IUserRolePinCodeManagerProps> = ({ userRole }) => {
  const isActiveAccess = (
    userRole.status === UserRoleStatus_enum.ACTIVE ||
    userRole.status === UserRoleStatus_enum.PENDING
  );

  const { hasSmartSigns, hasPropertyPinCode } = getInvitationAccess(userRole);

  const smartLockUserRoles = userRole.role.permissionScope === PermissionScope_enum.SMART_LOCK
    ? [userRole]
    : userRole.childUserRoles.filter(u => !!u.scopedSmartLock);

  const showSmartLockPinCodes = (
    !!smartLockUserRoles.length &&
    UnitModel.permissions.canRead() &&
    SmartLockModel.permissions.canRead()
  );

  const canSendInvitation = (
    isActiveAccess &&
    (hasSmartSigns || hasPropertyPinCode || showSmartLockPinCodes) &&
    userRole.role.key === RoleKey_enum.GUEST_PROPERTY_KEY &&
    authentication.hasPermission(PermissionKey_enum.User_SendMessage)
  );

  return (
    <>
      {showSmartLockPinCodes && <SmartLockPinCodeTable userRoles={smartLockUserRoles} />}
      {canSendInvitation && <SendInvitationForm userRole={userRole} />}
    </>
  )
};

export default UserRolePinCodeManager;
