import { Card } from 'antd';
import { withFormik } from 'formik';
import React, { useContext } from 'react';
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import {
  PermissionScope_enum,
  RoleKey_enum,
  usegetGrantableRolesQuery,
  UserRoleReason_enum,
} from '../../graphql/hasura/generated';
import { ModelsContext } from '../../hooks/providers';
import BasicLayout from '../../layouts/BasicLayout';
import LoadingLayout from '../../layouts/LoadingLayout';
import { authentication } from '../../stores';

import AdditionalContextSection from './AdditionalContextSection';
import DurationOfAccessSection from './DurationOfAccessSection';
import {
  handleSubmit,
  IGrantAccessFormValues,
  IGrantAccessPageMergedProps,
  IGrantAccessPageProps,
  mapPropsToValues,
  validate,
} from './formik';
import PermissionScopeSelect from './PermissionScopeSelect';
import PinCodesSection from './PinCodesSection';
import SCOPE_OPTIONS from './scope-options';
import ScopedIdSelect from './ScopedIdSelect';
import SelectRoleSection from './SelectRoleSection';
import SubmitFormSection from './SubmitFormSection';
import UserTabs from './UserTabs';

const GrantAccessPage: React.FC<IGrantAccessPageMergedProps> = (props) => {
  return (
    <BasicLayout pageTitle='Grant Access'>
      <UserTabs {...props} />

      <Card bordered={false} style={{ marginTop: 25, marginBottom: 75 }}>
        <PermissionScopeSelect {...props} />
        <ScopedIdSelect {...props} />
        <PinCodesSection {...props} />
        <SelectRoleSection {...props} />
        <DurationOfAccessSection {...props} />
        <AdditionalContextSection {...props} />
        <SubmitFormSection {...props} />
      </Card>
    </BasicLayout>
  );
};

const GrantAccessPageWithFormik = withFormik<IGrantAccessPageProps, IGrantAccessFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: true,
})(GrantAccessPage);

export default () => {
  const { getModel } = useContext(ModelsContext);
  const [defaultUserId] = useQueryParam('userId', StringParam);
  let [defaultPermissionScope] = useQueryParam('permissionScope', StringParam);
  const [defaultScopedIds] = useQueryParam('scopedIds', ArrayParam);
  const [defaultRoleKey] = useQueryParam('roleKey', StringParam);
  const [defaultReason] = useQueryParam('reason', StringParam);

  const enabledScopeOptions = SCOPE_OPTIONS.filter(({ scope }) => (
    authentication.grantableRoleScopes.includes(scope)
  ));

  defaultPermissionScope = defaultPermissionScope || enabledScopeOptions[0]?.scope || null;

  const { data: rolesData, loading: rolesLoading } = usegetGrantableRolesQuery({
    skip: !defaultRoleKey || !Object.values(RoleKey_enum).includes(defaultRoleKey as RoleKey_enum),
    variables: {
      where: {
        key: { _eq: defaultRoleKey as RoleKey_enum },
      },
    },
  });

  if (rolesLoading) {
    return <LoadingLayout pageTitle='Grant Access' />;
  }

  const defaultRole = rolesData?.roles ? rolesData.roles[0] : null;

  return (
    <GrantAccessPageWithFormik
      defaultUserId={defaultUserId}
      defaultPermissionScope={defaultPermissionScope as PermissionScope_enum | null | undefined}
      defaultScopedIds={(defaultScopedIds as string[]) || []}
      defaultRole={defaultRole}
      defaultReason={defaultReason as UserRoleReason_enum | null | undefined}
      UserModel={getModel('User')}
      UserRoleModel={getModel('UserRole')}
      enabledScopeOptions={enabledScopeOptions}
    />
  );
};
