import { InfoCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import React from 'react';

interface ITextWithIconTooltipProps {
  text: string;
  tooltip: TooltipPropsWithTitle;
  icon?: React.ReactNode;
}

export const TextWithIconTooltip: React.FC<ITextWithIconTooltipProps> = (props) => {
  const { text, tooltip } = props;

  const icon = props.icon
    ? <span>{props.icon}</span>
    : <InfoCircleTwoTone style={{ fontSize: '14px' }} />;

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <span style={{ marginRight: '5px' }}>{text}</span>
      <Tooltip {...tooltip}>{icon}</Tooltip>
    </div>
  );
};

export default TextWithIconTooltip;
