import { Table, Typography } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

import { TableActions } from '../../../../components/TableActions/TableActions';
import TabsManager, { ITab } from '../../../../components/TabsManager';
import { apiClient } from '../../../../graphql';
import {
  SmartLockDetailsFragment as SmartLock,
  usesmartLockAccessAuditQuery,
} from '../../../../graphql/api/generated';
import {
  AugustAccessUserRoleFragment,
  usegetAugustLockUserRolesQuery,
} from '../../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../../utils';
import { IModelAction } from '../../../typings';

import { mobileAccessColumns, mobileAccessExpandable } from './mobile-access-columns';
import { noAccessColumns } from './no-access-columns';
import { pinCodeAccessColumns } from './pin-code-access-columns';
import {
  IAugustMobileAccessUser,
  IAugustPinCodeUser,
  selectAugustAccessData,
} from './select-august-access-data';

interface IAugustAccessAuditProps {
  smartLock: SmartLock;
  actions?: IModelAction<SmartLock>[];
}

export const AugustAccessAudit: React.FC<IAugustAccessAuditProps> = ({ smartLock, actions }) => {
  // @TODO: Support full access audit via main API (instead of Hasura)
  const { data: smartLockData, loading: smartLockLoading } = usesmartLockAccessAuditQuery({
    client: apiClient,
    fetchPolicy: 'network-only',
    variables: {
      smartLockId: smartLock.smartLockId,
    },
    onError: error => displayErrorMessage(error),
  });

  const { data: userRolesData, loading: userRolesLoading } = usegetAugustLockUserRolesQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: { augustLockId: smartLock.sourceId },
    },
    onError: error => displayErrorMessage(error),
  });

  const augustLock = smartLockData?.smartLock.augustLock;
  const augustLockUserRoles = userRolesData?.custom_getAugustLockUserRoles.augustLockUserRoles || [];
  const loading = smartLockLoading || userRolesLoading;

  const {
    mobileAccessUsers,
    augustPinCodeUsers,
    noAccessUserRoles,
  } = selectAugustAccessData({ augustLock, augustLockUserRoles });

  const TABLE_PROPS: TableProps<any> = {
    loading,
    pagination: {
      pageSize: 25,
      hideOnSinglePage: true,
    },
    scroll: { x: true },
    style: { marginTop: 20 },
  };

  const accessTabs: ITab[] = [
    {
      loading,
      key: 'MOBILE_ACCESS',
      title: `Mobile Access (${mobileAccessUsers.length})`,
      empty: !loading && !mobileAccessUsers.length,
      onTouchedTab: () => {},
      content: () => (
        <Table<IAugustMobileAccessUser>
          columns={mobileAccessColumns}
          dataSource={mobileAccessUsers}
          rowKey={r => r.augustLockUser.userId}
          expandable={mobileAccessExpandable}
          {...TABLE_PROPS}
        />
      ),
    },
    {
      loading,
      key: 'PIN_CODE_ONLY_ACCESS',
      title: `Pin Code Only Access (${augustPinCodeUsers.length})`,
      empty: !loading && !augustPinCodeUsers.length,
      onTouchedTab: () => {},
      content: () => (
        <Table<IAugustPinCodeUser>
          columns={pinCodeAccessColumns}
          dataSource={augustPinCodeUsers}
          rowKey={r => r.augustPinCode.userID}
          {...TABLE_PROPS}
        />
      ),
    },
  ];

  if (noAccessUserRoles.length) {
    accessTabs.push({
      loading,
      key: 'NO_ACCESS',
      title: `No Access (${noAccessUserRoles.length})`,
      empty: !loading && !noAccessUserRoles.length,
      onTouchedTab: () => {},
      content: () => (
        <>
          <Typography.Paragraph style={{ paddingTop: 15, paddingBottom: 5 }}>
            These users have been assigned an active Chirp role to the unit
            but currently do NOT have mobile access to the August lock.
          </Typography.Paragraph>
          <Table<AugustAccessUserRoleFragment>
            columns={noAccessColumns}
            dataSource={noAccessUserRoles}
            rowKey={r => r.userRoleId}
            {...TABLE_PROPS}
          />
        </>
      ),
    });
  }

  return (
    <>
      <TabsManager
        tabs={accessTabs}
        tabsProps={{
          tabBarExtraContent: (
            <div style={{ marginRight: 25 }}>
              <TableActions actions={actions || []} row={smartLock} />
            </div>
          ),
        }}
      />
    </>
  );
}

export default AugustAccessAudit;
