import { LockFilled, SafetyCertificateFilled } from '@ant-design/icons';
import { Checkbox, Radio, Space, Tag } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';

import { FailurePlan_enum, GpioMode_enum, RelayRequiredRowFragmentDoc } from '../../graphql/hasura/generated';
import { Color, isGatewayManagerProperty } from '../../utils';

import model from './model';

const FAILURE_PLAN_OPTIONS = [
  {
    value: FailurePlan_enum.FAIL_SAFE,
    label: 'Unlock (Fail Safe)',
    icon: <SafetyCertificateFilled />,
    color: Color.Green,
  },
  {
    value: FailurePlan_enum.FAIL_SECURE,
    label: 'Lock (Fail Secure)',
    icon: <LockFilled />,
    color: Color.Orange,
  },
];

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 50,
  },
  {
    fieldName: 'readerAddress',
    maxLength: 100,
    hidden: ({ formikProps: { values } }) => (
      values.gpioMode === GpioMode_enum.INPUT ||
      !isGatewayManagerProperty(values.hub?.property)
    ),
  },
  {
    fieldName: 'readerSerialNumber',
    maxLength: 255,
    hidden: ({ formikProps: { values } }) => (
      values.gpioMode === GpioMode_enum.INPUT ||
      !isGatewayManagerProperty(values.hub?.property)
    ),
  },
  {
    fieldName: 'readerType',
    hidden: ({ formikProps: { values } }) => (
      values.gpioMode === GpioMode_enum.INPUT ||
      !values.readerAddress ||
      !isGatewayManagerProperty(values.hub?.property)
    ),
  },
  {
    fieldName: 'unlockOnInternetFailure',
    hidden: () => true,
  },
  {
    fieldName: 'inputDevice',
    hidden: ({ formikProps }) => formikProps.values.gpioMode === GpioMode_enum.OUTPUT,
  },
  {
    fieldName: 'childHubId',
    hidden: ({ formikProps: { values } }) => (
      values.gpioMode === GpioMode_enum.INPUT ||
      !isGatewayManagerProperty(values.hub?.property)
    ),
  },
  {
    fieldName: 'failurePlan',
    hidden: ({ formikProps }) => formikProps.values.gpioMode === GpioMode_enum.INPUT,
    render: ({ field, formikProps, FormItem, formItemProps }) => {
      const { values, setFieldValue } = formikProps;

      const outageType = !values.childHubId
        ? 'an Internet Outage'
        : 'a Chirp Hub IP Communication Failure';

      return (
        <FormItem {...formItemProps}>
          <div style={{ marginBottom: 5 }}>
            During {outageType}, the door will
          </div>
          <Space direction='vertical'>
            <Radio.Group
              onChange={({ target: { value } }: RadioChangeEvent) => {
                setFieldValue(field.name, value, true);

                if (value === FailurePlan_enum.FAIL_SECURE) {
                  setFieldValue('unlockOnInternetFailure', false, false);
                }
              }}
              defaultValue={FailurePlan_enum.FAIL_SAFE}
              value={values[field.name]}
            >
              <Space direction='vertical'>
                {FAILURE_PLAN_OPTIONS.map((o) =>
                  <Radio value={o.value} key={o.value}>
                    <Tag
                      color={o.color}
                      style={{ fontWeight: 'bold' }}
                    >
                      <span style={{ marginRight: '5px' }}>{o.icon}</span>
                      {o.label}
                    </Tag>
                  </Radio>
                )}
              </Space>
            </Radio.Group>
            {isGatewayManagerProperty(values.hub?.property) && values.childHubId &&
              <Checkbox
                checked={values.unlockOnInternetFailure}
                onChange={({ target: { checked } }: CheckboxChangeEvent) => {
                  setFieldValue('unlockOnInternetFailure', checked, true);
                }}
                disabled={values.failurePlan === FailurePlan_enum.FAIL_SECURE}
              >
                Keep door unlocked during Internet Outage
              </Checkbox>
            }
          </Space>
        </FormItem>
      );
    },
  }
]);

model.setFormOptions({
  fields: formFields,
  requiredRowFragment: RelayRequiredRowFragmentDoc,
});
