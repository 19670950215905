import { FormikBag, FormikErrors, FormikProps } from 'formik';
import parsePhoneNumberFromString from 'libphonenumber-js';
import validator from 'validator';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  InvitationTemplate,
  MessageMethod,
  sendInvitationDocument,
  sendInvitationMutation,
  sendInvitationMutationVariables,
} from '../../../graphql/api/generated';
import { FeatureFlagKey_enum, UserRoleDetailsFragment, UserRoleStatus_enum } from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';

export function getInvitationAccess(userRole: UserRoleDetailsFragment) {
  const hasSmartSigns = userRole.managedWithinProperty?.propertyFeatureFlags.some(f => (
    f.featureFlagKey === FeatureFlagKey_enum.SMART_SIGNS
  )) || false;

  const hasPropertyPinCode = !!userRole.pinCodes.length;
  const hasSmartLockPinCodes = userRole.childUserRoles.some(u => (
    !!u.scopedSmartLock &&
    (u.status === UserRoleStatus_enum.ACTIVE || u.status === UserRoleStatus_enum.PENDING)
  ));

  return { hasSmartSigns, hasPropertyPinCode, hasSmartLockPinCodes };
}

export interface ISendInvitationFormValues {
  messageMethod: MessageMethod;
  phoneNumber?: string | null;
  email?: string | null;
  invitationSent: boolean;
}

export interface ISendInvitationFormProps {
  userRole: UserRoleDetailsFragment;
}

export interface ISmartLockPinCodeFormMergedProps extends
  ISendInvitationFormProps, FormikProps<ISendInvitationFormValues> {}

export function mapPropsToValues(props: ISendInvitationFormProps): ISendInvitationFormValues {
  return {
    messageMethod: MessageMethod.SMS,
    invitationSent: false,
  };
}

export function validate(values: ISendInvitationFormValues, props: ISendInvitationFormProps) {
  const errors: FormikErrors<ISendInvitationFormValues> = {};

  if (values.messageMethod === MessageMethod.SMS) {
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Please enter a phone number';
    } else if (!(parsePhoneNumberFromString(values.phoneNumber || '', 'US')?.isValid())) {
      errors.phoneNumber = 'Please enter a valid phone number';
    }
  }

  if (values.messageMethod === MessageMethod.EMAIL) {
    if (!values.email) {
      errors.email = 'Please enter an email';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Please enter a valid email';
    }
  }

  return errors;
}

export async function handleSubmit(
  values: ISendInvitationFormValues,
  formikBag: FormikBag<ISendInvitationFormProps, ISendInvitationFormValues>
) {
  try {
    const { userRole } = formikBag.props;
    const { hasSmartSigns, hasPropertyPinCode, hasSmartLockPinCodes } = getInvitationAccess(userRole);

    if (!hasSmartSigns && !hasPropertyPinCode && !hasSmartLockPinCodes) {
      throw new Error('This invitation does not include any access information');
    }

    await apiClient.mutate<sendInvitationMutation, sendInvitationMutationVariables>({
      mutation: sendInvitationDocument,
      variables: {
        input: {
          invitationTemplate: InvitationTemplate.UNREGISTERED_USER,
          messageMethod: values.messageMethod,
          userRoleId: userRole.userRoleId,
          phoneNumber: values.phoneNumber,
          email: values.email,
        },
      },
    });

    formikBag.setFieldValue('invitationSent', true);
  } catch (error) {
    displayErrorMessage(error);
    formikBag.setFieldValue('invitationSent', false);
  }

  formikBag.setSubmitting(false);
}
