import { Modal, notification, Tag } from 'antd';
import { kebabCase } from 'lodash';
import React from 'react';

import QrCode from '../../components/QrCode';
import { CHIRP_UNIVERSAL_LINK, STRATIS_UNIVERSAL_LINK } from '../../config';
import {
  AccessPointActionsFragment,
  Custom_UnlockAccessPointResult,
  PermissionKey_enum,
  unlockAccessPointDocument,
  unlockAccessPointMutation,
  unlockAccessPointMutationVariables,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { getErrorCode, isStratisProperty } from '../../utils';
import { HubStatusTable } from '../Hub/components/HubStatusTable';

import model from './model';

export const qrCodeAction = model.createAction<AccessPointActionsFragment>({
  label: () => 'QR Code',
  description: 'Generates a QR code for this access point',
  enabledByModel: () => true,
  async executes(accessPoint) {
    const stratisProperty = isStratisProperty(accessPoint.property);

    const { accessPointId, deprecatedAccessPointId, property, name } = accessPoint;
    const propertyName = property?.name;
    const title = `${propertyName ? `${propertyName} - ` : ''}${name}`;

    const qrCodeUrl = stratisProperty
      ? `${STRATIS_UNIVERSAL_LINK}/chirp/entry/${accessPointId}`
      : `${CHIRP_UNIVERSAL_LINK}/entry/${deprecatedAccessPointId}`;

    const qrCodeFilename = `${kebabCase(title)}.png`;

    Modal.info({
      title,
      icon: null,
      maskClosable: true,
      okButtonProps: {
        style: { display: 'none' },
      },
      okCancel: true,
      cancelText: 'Close',
      width: '600px',
      content: (
        <QrCode url={qrCodeUrl} filename={qrCodeFilename} />
      ),
    });
  },
});

export const unlockAction = model.createAction<AccessPointActionsFragment>({
  label: () => 'Unlock',
  description: 'Unlocks this access point',
  enabledByModel: ({ hasPermission }) => (
    // @TODO: Check staff access only?
    hasPermission(PermissionKey_enum.AccessPoint_Unlock)
  ),
  enabledByRow: ({ proximityRestriction, archivedAt }) => (
    !archivedAt && proximityRestriction === false
  ),
  confirmation: {
    title: () => 'Unlock access point',
    content: accessPoint => `Are you sure you want to unlock ${accessPoint.name}?`,
  },
  async executes({ accessPointId, name }, { hasuraClient }) {
    try {
      const { data } = await hasuraClient.mutate<
        unlockAccessPointMutation,
        unlockAccessPointMutationVariables
      >({
        mutation: unlockAccessPointDocument,
        variables: { accessPointId },
      });

      const result = data?.custom_unlockAccessPoint.result;
      const message = result === Custom_UnlockAccessPointResult.SUCCESS
        ? `${name} successfully unlocked`
        : `Unlock request sent to ${name}`;

      if (result && message) {
        notification.success({
          message: 'Success',
          description: message,
        });
      }
    } catch (error) {
      const errorCode = getErrorCode(error as Error);

      if (
        authentication.hasPermission(PermissionKey_enum.Hub_Read) && (
          errorCode === 'TIMEOUT' || // Timed out waiting for acknowledgment
          errorCode === 'unexpected' || // Hasura is unable to reach the action endpoint on the main API
          errorCode === 'UNEXPECTED' // Unexpected error within mutation resolver
        )
      ) {
        Modal.error({
          title: `Failed to unlock ${name}`,
          centered: true,
          maskClosable: true,
          okButtonProps: {
            style: { display: 'none' },
          },
          okCancel: true,
          cancelText: 'Close',
          width: '60%',
          content: (
            <HubStatusTable hubFilter={{ accessPointIds: [accessPointId] }} />
          ),
        });
      } else {
        throw error;
      }
    }
  },
});

// This is not really an "action".
// It's just a way of displaying whether or not the AP is archived
// since there is no ability to filter by archived vs. unarchived.
export const archivedAction = model.createAction<AccessPointActionsFragment>({
  enabledByModel: () => true,
  enabledByRow: ({ archivedAt }) => !!archivedAt,
  render: () => {
    return (
      <Tag
        style={{ fontWeight: 'bold', marginTop: '5px' }}
      >
        ARCHIVED
      </Tag>
    );
  },
});
