import { Descriptions, Spin } from 'antd';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import {
  getRelayLogsDocument,
  getRelayLogsTotalDocument,
  RelayLogTableFragment,
  RelayLogTableFragmentDoc,
} from '../../graphql/api/generated';
import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';
import { userRoleLoader } from '../../graphql/hasura/hasuraClient';
import { useMainApiTableData } from '../../hooks/useTableManager';
import { createdAtColumn } from '../common/columns';
import { RelayModel } from '../Relay/model';
import { IModelTableExpandable } from '../typings';
import { UserRoleModel } from '../UserRole/model';

import * as columns from './columns';
import model from './model';

const { Item } = Descriptions;

const RelayLockLogExpandedRow: React.FC<RelayLogTableFragment> = ({ restricted, inputRelay }) => {
  const userRoleId = restricted?.userRoleId;
  const readerInput = restricted?.readerInput;

  const {
    loading, value: userRole,
  } = useAsync(() => userRoleLoader.getUserRoleByPk(userRoleId), [userRoleId]);

  if (loading) {
    return <><Spin style={{ marginRight: '10px' }} /> Loading...</>;
  }

  return (
    <Descriptions column={1} style={{ width: '100%' }}>
      {userRoleId && (
        <Item span={1} label={userRole ? 'Assigned Role' : 'Assigned Role ID'}>
          {userRole ? UserRoleModel.routes.renderUniqueRowLink(userRole) : userRoleId}
        </Item>
      )}
      {readerInput && <Item span={1} label='Reader Input'>{readerInput}</Item>}
      {inputRelay && (
        <Item span={1} label='Input Relay'>{RelayModel.routes.renderRowLink(inputRelay)}</Item>
      )}
    </Descriptions>
  );
}

export const expandable: IModelTableExpandable<RelayLogTableFragment> = {
  rowExpandable: ({ restricted, inputRelay }) => (
    !!restricted?.userRoleId || !!restricted?.readerInput || !!inputRelay
  ),
  // This callback cannot render hooks, so we have to render them inside of another component
  expandedRowRender: (props) => <RelayLockLogExpandedRow {...props} />,
};

const commonTableConfig = {
  expandable,
  useTableData: useMainApiTableData({
    totalOperation: getRelayLogsTotalDocument,
    listOperation: getRelayLogsDocument,
  }),
  fragment: RelayLogTableFragmentDoc, // @TODO: Not used, but required by config
};

export const RelayLogsMainTable = model.createTable<RelayLogTableFragment>({
  ...commonTableConfig,
  columns: [
    columns.idColumn,
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.relayColumn,
    columns.userColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
});

export const RelayLogsByRelayTable = model.createTable<RelayLogTableFragment, string>({
  ...commonTableConfig,
  fixedQueryFilters: relayId => ({ relayId }),
  columns: [
    columns.accessPointColumn,
    columns.userColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
});

export const RelayLogsByUserTable = model.createTable<RelayLogTableFragment, string>({
  ...commonTableConfig,
  fixedQueryFilters: userId => ({ userId }),
  enabled: ({ hasPermission, currentPermissionScope }) => (
    hasPermission(PermissionKey_enum.RelayLogPrivate_Read) &&
    currentPermissionScope === PermissionScope_enum.GLOBAL
  ),
  columns: [
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.relayColumn,
    columns.unlockMethodColumn,
    columns.unlockResultColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
