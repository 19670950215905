import { Descriptions, Spin } from 'antd';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import {
  getSmartLockLogsDocument,
  getSmartLockLogsTotalDocument,
  SmartLockLogTableFragment,
  SmartLockLogTableFragmentDoc,
} from '../../graphql/api/generated';
import { userRoleLoader } from '../../graphql/hasura/hasuraClient';
import { useMainApiTableData } from '../../hooks/useTableManager';
import { createdAtColumn } from '../common/columns';
import { IModelTableExpandable } from '../typings';
import UserRoleModel from '../UserRole/model';

import * as columns from './columns';
import model from './model';

const { Item } = Descriptions;

const SmartLockLogExpandedRow: React.FC<SmartLockLogTableFragment> = (props) => {
  const { actingUserRoleId, sourceId } = props;

  const {
    loading, value: userRole,
  } = useAsync(() => userRoleLoader.getUserRoleByPk(actingUserRoleId), [actingUserRoleId]);

  if (loading) {
    return <><Spin style={{ marginRight: '10px' }} /> Loading...</>;
  }

  return (
    <Descriptions column={1} style={{ width: '100%' }}>
      {actingUserRoleId && (
        <Item span={1} label={userRole ? 'Assigned Role' : 'Assigned Role ID'}>
          {userRole ? UserRoleModel.routes.renderUniqueRowLink(userRole) : actingUserRoleId}
        </Item>
      )}
      {sourceId && <Item span={1} label={'Source ID'}>{sourceId}</Item>}
    </Descriptions>
  );
}

export const expandable: IModelTableExpandable<SmartLockLogTableFragment> = {
  rowExpandable: ({ actingUserRoleId, sourceId }) => !!actingUserRoleId || !!sourceId,
  // This callback cannot render hooks, so we have to render them inside of another component
  expandedRowRender: (props) => <SmartLockLogExpandedRow {...props} />,
};

const commonTableConfig = {
  expandable,
  useTableData: useMainApiTableData({
    totalOperation: getSmartLockLogsTotalDocument,
    listOperation: getSmartLockLogsDocument,
  }),
  fragment: SmartLockLogTableFragmentDoc, // @TODO: Not used, but required by config
};

export const SmartLockLogsMainTable = model.createTable<SmartLockLogTableFragment>({
  ...commonTableConfig,
  columns: [
    columns.idColumn,
    columns.propertyColumn,
    columns.unitColumn,
    columns.smartLockColumn,
    columns.typeColumn,
    columns.actingUserColumn,
    createdAtColumn,
  ],
});

export const SmartLockLogsByUnitTable = model.createTable<SmartLockLogTableFragment, string>({
  ...commonTableConfig,
  fixedQueryFilters: unitId => ({ unitId }),
  columns: [
    columns.smartLockColumn,
    columns.typeColumn,
    columns.actingUserColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const SmartLockLogsBySmartLockTable = model.createTable<SmartLockLogTableFragment, string>({
  ...commonTableConfig,
  title: 'Logs',
  fixedQueryFilters: smartLockId => ({ smartLockId }),
  columns: [
    columns.typeColumn,
    columns.actingUserColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const SmartLockLogsByActingUserTable = model.createTable<SmartLockLogTableFragment, string>({
  ...commonTableConfig,
  fixedQueryFilters: actingUserId => ({ actingUserId }),
  columns: [
    columns.propertyColumn,
    columns.unitColumn,
    columns.smartLockColumn,
    columns.typeColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
