import React from 'react';

const confirmationList = [
  'Revoke all of the user\'s active sessions',
  'Revoke all of the user\'s access to Chirp',
  'Revoke access from all of the user\'s guests',
  'Prevent the user from logging in or using Chirp in any way',
];

const listStyle = { listStyle: 'circle', marginLeft: -10 };

export const DisableAccountConfirmation = () => {
  return (
    <>
      <p>Are you sure you want to disable this user's account? This will:</p>
      <ul style={listStyle}>
        {confirmationList.map(desc => (
          <li key={desc}>{desc}</li>
        ))}
      </ul>
      <br />
      <p style={{ fontSize: '12px' }}>
        <em>
          Resident app access, Pin Codes, and Credentials will be disabled but&nbsp;
          <strong>not</strong> permanently deleted for residents synced by a PMS.
          These will be preserved in case the user's account is re-enabled.
        </em>
      </p>
    </>
  );
}

export default DisableAccountConfirmation;
