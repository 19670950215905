import { Card, Tabs, Typography } from 'antd';
import React from 'react';

import { hasuraClient } from '../../graphql';
import {
  getGrantableRolesDocument,
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleReason_enum,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { TextField } from '../ModelForm/form-fields';
import ModelFormSelect from '../ModelForm/ModelFormSelect';

import {
  IGrantAccessFormValues,
  IGrantAccessPageMergedProps,
  mapPropsToValues,
  UserTab,
} from './formik';
import NewUserForm from './NewUserForm';

export const UserTabs: React.FC<IGrantAccessPageMergedProps> = (props) => {
  const {
    defaultUserId,
    defaultPermissionScope,
    defaultScopedIds,
    UserModel,
    UserRoleModel,
    enabledScopeOptions,
    ...formikProps
  } = props;

  const { setFieldValue, resetForm, errors: formikErrors, isSubmitting } = formikProps;
  const { activeUserTab, userId, permissionScope, scopedIds } = formikProps.values;

  const canCreateRegisteredUsers = UserModel.permissions.canCreate();
  const canGrantAnyScopedUserRole = authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped);

  const canCrantGuestPasses = canGrantAnyScopedUserRole ||
    authentication.currentRoleRelationships.some(r => (
      r.relatedRole.key === RoleKey_enum.GUEST_PROPERTY_KEY &&
      r.canReadUserRole &&
      r.canGrantUserRole
    ));

  const canGrantUnregisteredUsers = canGrantAnyScopedUserRole ||
    authentication.currentRoleRelationships.some(r => (
      // @TODO: Eventually we will probably allow this flow to be used without
      // the ability to grant smart lock access (i.e., property access only)
      r.relatedRole.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER &&
      r.canReadUserRole &&
      r.canGrantUserRole
    ));

  return (
    <Card bordered={false}>
      <Tabs
        size='large'
        animated={false}
        defaultActiveKey={activeUserTab}
        activeKey={activeUserTab}
        onChange={async (nextActiveTab) => {
          if (nextActiveTab !== activeUserTab) {
            if (activeUserTab === 'UNREGISTERED_USER' || nextActiveTab === 'UNREGISTERED_USER') {
              const nextValues: IGrantAccessFormValues = {
                ...mapPropsToValues(props),
                activeUserTab: nextActiveTab as UserTab,
                reason: null, // Reset
              };

              if (nextActiveTab === 'UNREGISTERED_USER') {
                const { data } = await hasuraClient.query({
                  query: getGrantableRolesDocument,
                  variables: {
                    where: {
                      key: { _eq: RoleKey_enum.GUEST_PROPERTY_KEY },
                    },
                  },
                });

                nextValues.role = data.roles ? data.roles[0] : null;
                nextValues.permissionScope = PermissionScope_enum.PROPERTY;
                // Ensure values that can be initialized from query params are reset properly
                nextValues.userId = null;
                nextValues.scopedIds = permissionScope !== PermissionScope_enum.PROPERTY ? [] : scopedIds;
                nextValues.reason = UserRoleReason_enum.PROSPECT_TOUR; // Default (but editable)
              } else {
                nextValues.permissionScope = defaultPermissionScope;
                nextValues.scopedIds = defaultPermissionScope && defaultPermissionScope !== PermissionScope_enum.PROPERTY
                  ? (defaultScopedIds || [])
                  : scopedIds;
              }

              resetForm(nextValues);
            } else {
              setFieldValue('activeUserTab', nextActiveTab);
              setFieldValue('userId', defaultUserId || null);
              setFieldValue('sendSms', false);
            }
          }
        }}
      >
        <Tabs.TabPane
          key='NEW_USER'
          tab='New User'
          disabled={!canCreateRegisteredUsers || isSubmitting}
          style={{ paddingTop: 10 }}
        >
          <NewUserForm UserModel={UserModel} formikProps={formikProps} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='EXISTING_USER'
          tab='Existing User'
          disabled={isSubmitting}
          style={{ paddingTop: 10 }}
        >
          <Typography.Paragraph>
            Search for an existing Chirp user to grant access to.
            {canCreateRegisteredUsers
              ? ` If you cannot find who you're looking for, try entering them as a new user.`
              : ''}
          </Typography.Paragraph>
          <ModelFormSelect
            label='User'
            fieldName='userId'
            formItemProps={{ className: 'label-align-top' }}
            modelSelectProps={{
              style: { width: '100%' },
              model: UserModel,
              value: userId,
              allowClear: true,
              required: true,
              disabled: isSubmitting,
            }}
            setFieldValue={setFieldValue}
            errors={formikErrors}
            submitCount={1}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='UNREGISTERED_USER'
          tab='Self-Guided Tour User'
          disabled={!(canCrantGuestPasses && canGrantUnregisteredUsers) || isSubmitting}
          style={{ paddingTop: 10 }}
        >
          <Typography.Paragraph>
            Generate a temporary guest pass for property access (and optionally unit access)
            without requiring the mobile app.
          </Typography.Paragraph>
          <TextField
            {...formikProps}
            formItemLayout={{}}
            className='label-align-top'
            required
            isNew
            label='Guest Name'
            field={UserRoleModel.introspection.getField('nickname')}
            maxLength={100}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default UserTabs;
