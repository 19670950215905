import { Descriptions } from 'antd';
import React from 'react';

import {
  PropertyTableFragment,
  usegetPropertyDetailsQuery,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { isVirtualDirectoryEnabled } from '../../../utils';
import { createdAtColumn, updatedAtColumn } from '../../common/columns';
import * as columns from '../columns';

const { Item } = Descriptions;

const PropertyDetailsLayout = createDetailsLayout<PropertyTableFragment>();

export const PropertyDetailsPage: React.FC<any> = () => {
  return (
    <PropertyDetailsLayout
      modelName='Property'
      useQuery={(propertyId) => {
        return usegetPropertyDetailsQuery({
          variables: { propertyId },
        });
      }}
      body={(property) => {
        const virtualDirectoryEnabled = isVirtualDirectoryEnabled(property);

        return (
          <>
            <Descriptions
              column={1}
              size='middle'
              bordered
              style={{ width: '49%', float: 'left' }}
            >
              {[
                columns.organizationColumn,
                columns.nameColumn,
                columns.timezoneColumn,
                columns.propertyFeatureFlagsColumn,
                createdAtColumn,
                updatedAtColumn,
              ].map((column, index) => (
                <Item key={index} label={column.title}>
                  {column.render(property)}
                </Item>
              ))}
            </Descriptions>
            <Descriptions
              column={1}
              size='middle'
              bordered
              style={{ width: '49%', float: 'right' }}
            >
              {[
                columns.imageColumn,
                columns.addressColumn,
                columns.supportPhoneNumberColumn,
                columns.supportEmailColumn,
                virtualDirectoryEnabled ? columns.vdPhoneNumberColumn : null,
                virtualDirectoryEnabled ? columns.vdLeasingOfficeHoursColumn : null,
              ].map((column, index) => {
                if (!column) {
                  return null;
                }

                return (
                  <Item key={index} label={column.title}>
                    {column.render(property)}
                  </Item>
                );
              })}
            </Descriptions>
          </>
        );
      }}
    />
  );
};

export default PropertyDetailsPage;
