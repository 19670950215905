import { FormikBag, FormikErrors, FormikProps } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import pluralize from 'pluralize';
import validator from 'validator';

import { apiClient } from '../../graphql/api/apiClient';
import {
  ensureUserExists,
  grantGlobalUserRole,
  grantScopedUserRoles,
} from '../../graphql/api/operations';
import {
  GrantableRoleFragment,
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleReason_enum,
} from '../../graphql/hasura/generated';
import { hasuraClient } from '../../graphql/hasura/hasuraClient';
import { createGuestPass } from '../../graphql/hasura/operations';
import { IModel } from '../../models/typings';
import history from '../../routes/history';
import { displayErrorMessage, parsePhoneNumber } from '../../utils';

import SCOPE_OPTIONS from './scope-options';

const MAX_USER_ROLES_PER_REQUEST = 25;

export type UserTab = 'NEW_USER' | 'EXISTING_USER' | 'UNREGISTERED_USER';

export interface IGrantAccessFormValues {
  // Normally we would use React state for these values,
  // but it causes the whole form to be wiped for some reason.
  activeUserTab: UserTab;
  filterByVacants: boolean;

  userId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  nickname?: string | null;

  permissionScope?: PermissionScope_enum | null;
  scopedIds: string[];

  enablePropertyPinCode: boolean;
  smartLockIds: string[];

  role?: GrantableRoleFragment | null;

  activatesAt: Date;
  expiresAt?: Date | null;

  reason?: UserRoleReason_enum | null;
  notes?: string | null;

  sendSms: boolean;
  acknowledged: boolean;
}

export interface IGrantAccessPageProps {
  defaultUserId?: string | null;
  defaultPermissionScope?: PermissionScope_enum | null;
  defaultScopedIds?: string[] | null | undefined;
  defaultRole?: GrantableRoleFragment | null;
  defaultReason?: UserRoleReason_enum | null;
  UserModel: IModel;
  UserRoleModel: IModel;
  enabledScopeOptions: typeof SCOPE_OPTIONS;
}

export interface IGrantAccessPageMergedProps extends
  IGrantAccessPageProps, FormikProps<IGrantAccessFormValues> {}

export function getFieldRequirements(role?: GrantableRoleFragment | null) {
  const reasonToGrantRequired = (
    role?.key === RoleKey_enum.INTEGRATOR_GATEWAY ||
    role?.key === RoleKey_enum.STAFF_PROPERTY_KEY ||
    role?.key === RoleKey_enum.STAFF_UNIT_KEY ||
    role?.key === RoleKey_enum.GUEST_PROPERTY_KEY ||
    role?.key === RoleKey_enum.GUEST_UNIT_KEY
  );

  const expirationDateRequired = (
    role?.key === RoleKey_enum.STAFF_PROPERTY_KEY ||
    role?.key === RoleKey_enum.STAFF_UNIT_KEY ||
    role?.key === RoleKey_enum.GUEST_PROPERTY_KEY ||
    role?.key === RoleKey_enum.GUEST_UNIT_KEY
  );

  return {
    reasonToGrantRequired,
    expirationDateRequired: expirationDateRequired || !!role?.maxAccessDurationSeconds,
    maxAccessDurationSeconds: role?.maxAccessDurationSeconds || null,
  };
}

export function mapPropsToValues(props: IGrantAccessPageProps): IGrantAccessFormValues {
  const {
    UserModel,
    defaultUserId,
    defaultPermissionScope,
    defaultScopedIds,
    defaultRole,
    defaultReason,
  } = props;

  return {
    activeUserTab: (!UserModel.permissions.canCreate() || defaultUserId)
      ? 'EXISTING_USER'
      : 'NEW_USER',
    filterByVacants: false,
    userId: defaultUserId,
    permissionScope: defaultPermissionScope,
    scopedIds: defaultScopedIds || [],
    enablePropertyPinCode: false,
    smartLockIds: [],
    role: defaultRole,
    activatesAt: new Date(),
    reason: defaultReason,
    sendSms: false,
    acknowledged: false,
  };
}

export function validate(values: IGrantAccessFormValues, props: IGrantAccessPageProps) {
  const errors: FormikErrors<IGrantAccessFormValues> = {};

  if (values.permissionScope && values.scopedIds.length > MAX_USER_ROLES_PER_REQUEST) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = (
      `A maximum of ${MAX_USER_ROLES_PER_REQUEST} ${pluralize(values.permissionScope.toLowerCase())} may be selected`
    );
  }

  if (!values.acknowledged) {
    return errors;
  }

  if (values.activeUserTab === 'NEW_USER') {
    if (!values.firstName) {
      errors.firstName = `Please enter the user's first name`;
    }

    if (!values.lastName) {
      errors.lastName = `Please enter the user's last name`;
    }

    if (values.email && !validator.isEmail(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = `Please enter the user's phone number`;
    } else if (!(parsePhoneNumberFromString(values.phoneNumber || '', 'US')?.isValid())) {
      errors.phoneNumber = 'Please enter a valid phone number';
    }
  }

  if (values.activeUserTab === 'EXISTING_USER' && !values.userId) {
    errors.userId = 'Please select a user';
  }

  if (values.activeUserTab === 'UNREGISTERED_USER' && !values.nickname) {
    errors.nickname = `Please enter the guest's name`;
  }

  if (!values.permissionScope) {
    errors.permissionScope = 'Please select scope of access';
  }

  if (
    values.permissionScope &&
    values.permissionScope !== PermissionScope_enum.GLOBAL &&
    !values.scopedIds.length
  ) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = `Please select at least one ${values.permissionScope.toLowerCase()}`;
  }

  if (
    values.activeUserTab === 'UNREGISTERED_USER' &&
    values.smartLockIds.length > MAX_USER_ROLES_PER_REQUEST
  ) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.smartLockIds = `A maximum of ${MAX_USER_ROLES_PER_REQUEST} smart locks may be selected`;
  }

  if (!values.role) {
    errors.role = 'Please select a role';
  }

  if (!values.activatesAt) {
    errors.activatesAt = `Please select an activation date`;
  }

  const {
    maxAccessDurationSeconds,
    expirationDateRequired,
    reasonToGrantRequired,
  } = getFieldRequirements(values.role);

  if ((expirationDateRequired || maxAccessDurationSeconds) && !values.expiresAt) {
    errors.expiresAt = `Please select an expiration date`;
  }

  if (maxAccessDurationSeconds && values.expiresAt) {
    const maxExpiresAt = moment(values.activatesAt || new Date())
      .add(maxAccessDurationSeconds, 'seconds');

    if (moment(values.expiresAt).isAfter(maxExpiresAt)) {
      errors.expiresAt = 'Expiration date exceeds the maximum duration of access';
    }
  }

  if (reasonToGrantRequired && !values.reason) {
    errors.reason = `Please select a reason`;
  }

  if (
    values.activatesAt && values.expiresAt &&
    values.activatesAt.getTime() >= values.expiresAt.getTime()
  ) {
    errors.expiresAt = 'Please ensure the expiration date is after the activation date';
  }

  if (values.expiresAt && moment(values.expiresAt).isSameOrBefore(moment(new Date()))) {
    errors.expiresAt = 'Please ensure the expiration date is not in the past';
  }

  return errors;
}

export async function handleSubmit(
  values: IGrantAccessFormValues,
  formikBag: FormikBag<IGrantAccessPageProps, IGrantAccessFormValues>
) {
  try {
    const { activeUserTab } = values;
    let { userId } = values;

    if (activeUserTab === 'NEW_USER') {
      const { firstName, lastName, email } = values;
      const phoneNumber = parsePhoneNumber(values.phoneNumber);

      if (!firstName || !lastName || !phoneNumber) {
        throw new Error('Missing necessary user information');
      }

      const user = await ensureUserExists({
        input: { firstName, lastName, phoneNumber, email },
      });

      userId = user?.userId;
    }

    const { scopedIds, permissionScope, role } = values;

    let redirect: string | undefined;

    if (activeUserTab === 'UNREGISTERED_USER') {
      const userRoleId = await createGuestPass({
        input: {
          scopedPropertyId: scopedIds[0] as string,
          nickname: values.nickname as string,
          activatesAt: values.activatesAt.toISOString(),
          expiresAt: values.expiresAt?.toISOString() as string,
          reason: values.reason,
          notes: values.notes,
          enablePropertyPinCode: values.enablePropertyPinCode,
          scopedSmartLockIds: values.smartLockIds,
        },
      });

      if (userRoleId) {
        redirect = `/assigned-roles/details/${userRoleId}`;
      }
    } else {
      if (!userId) {
        throw new Error('Missing user information');
      }

      if (!role) {
        throw new Error('Missing role information');
      }

      if (permissionScope === PermissionScope_enum.GLOBAL) {
        await grantGlobalUserRole({
          input: {
            roleId: role.roleId,
            assignedToUserId: userId,
            sendSms: values.sendSms,
          },
        });
      } else {
        await grantScopedUserRoles({
          input: {
            scopedIds,
            roleId: role.roleId,
            assignedToUserId: userId,
            nickname: values.nickname,
            activatesAt: values.activatesAt.toISOString(),
            expiresAt: values.expiresAt?.toISOString(),
            reason: values.reason,
            notes: values.notes,
            sendSms: values.sendSms,
          },
        });
      }

      redirect = `/users/details/${userId}`;
    }

    // Ensure that cached tables refetch data when redirected
    await hasuraClient.resetStore();
    await apiClient.resetStore();

    if (redirect) {
      history.push(redirect);
    }
  } catch (error) {
    formikBag.setSubmitting(false);
    displayErrorMessage(error);
  }
}
