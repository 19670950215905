import { notification } from 'antd';

import DisableAccountConfirmation from '../../components/DisableAccountConfirmation';
import {
  InvitationTemplate,
  sendInvitationDocument,
  sendInvitationMutation,
  sendInvitationMutationVariables,
} from '../../graphql/api/generated';
import {
  AccountStatus_enum,
  PermissionKey_enum,
  updateUserDocument,
  updateUserMutation,
  updateUserMutationVariables,
  UsersMainTableFragment,
} from '../../graphql/hasura/generated';
import { buildGrantAccessPageUrl } from '../../pages/GrantAccess/buildGrantAccessPageUrl';
import { authentication } from '../../stores';
import { displayErrorMessage, formatUserName } from '../../utils';

import model from './model';

export const grantAccessAction = model.createAction({
  label: () => 'Grant Access',
  description: 'Allows you to grant this user access to the admin panel and/or access app',
  enabledByModel: () => {
    return !!authentication.grantableRoleScopes.length;
  },
  enabledByRow: ({ accountStatus }) => accountStatus === AccountStatus_enum.ENABLED,
  executes({ userId }, { history }) {
    history.push(buildGrantAccessPageUrl({ userId }));
  },
});

export const sendInvitationAction = model.createAction<UsersMainTableFragment>({
  label: () => 'Send Invitation',
  description: 'Sends this user an SMS invitation',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.User_SendMessage)
  ),
  confirmation: {
    title: () => 'Send SMS Invitation',
    content: user => (
      `Are you sure you want to send ${formatUserName(user) || 'this user'} an SMS invitation?`
    ),
  },
  executes: async ({ userId, phoneNumber }, context) => {
    if (!phoneNumber) {
      displayErrorMessage(new Error('This user has no phone number'));
      return;
    }

    await context.apiClient.mutate<sendInvitationMutation, sendInvitationMutationVariables>({
      mutation: sendInvitationDocument,
      variables: {
        input: {
          userId,
          invitationTemplate: InvitationTemplate.GENERIC_WELCOME,
        },
      },
    });
  },
});

export const updateAccountStatusAction = model.createAction({
  label: ({ accountStatus }) => {
    const labelAction = accountStatus === AccountStatus_enum.DISABLED
    ? 'Enable'
    : 'Disable';

    return `${labelAction} Account`;
  },
  description: `Enables or disables user's account (if applicable)`,
  enabledByModel: ({ limitStratisPermissions, hasPermission }) => (
    !limitStratisPermissions &&
    hasPermission(PermissionKey_enum.User_UpdateAccountStatus)
  ),
  confirmation: {
    content: ({ accountStatus }) => (
      accountStatus === AccountStatus_enum.ENABLED
        ? DisableAccountConfirmation()
        : 'Are you sure you want to enable this user\'s account?'
    ),
  },
  async executes({ userId, accountStatus }, { hasuraClient, resetStores }) {
    const enable = accountStatus === AccountStatus_enum.DISABLED;

    const { data } = await hasuraClient
      .mutate<updateUserMutation, updateUserMutationVariables>({
        mutation: updateUserDocument,
        variables: {
          userId,
          _set: {
            accountStatus: enable ? AccountStatus_enum.ENABLED : AccountStatus_enum.DISABLED,
          },
        },
      });

    if (!data?.custom_updateUser.user) {
      throw new Error(`Failed to ${enable ? 'enable' : 'disable'} user's account`);
    }

    await resetStores();

    notification.success({
      message: 'Success',
      description: `The user's account was successfully ${enable ? 'enabled' : 'disabled'}`,
    });
  },
});
