import React from 'react';

import {
  renderUserOrUserRoleString,
  UserOrUserRoleLink,
} from '../../components/UserOrUserRoleLink';
import {
  PermissionScope_enum,
  PinCodeAssignedToFragment,
  PinCodePrivateFragment,
  PropertyLabelFragment,
  UserLabelFragment,
} from '../../graphql/hasura/generated';
import { PropertyModel } from '../Property/model';
import { UserModel } from '../User/model';

import model from './model';

export const propertyColumn = model.createColumn<{ property: PropertyLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'propertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const codeColumn = model.createColumn<{ private?: PinCodePrivateFragment | null }>({
  title: 'Code',
  render: (pinCode) => {
    if (pinCode.private?.code) {
      return pinCode.private.code;
    }

    return '******';
  },
});

export const assignedToColumn = model.createColumn<PinCodeAssignedToFragment>({
  title: 'Assigned To',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'assignedToUserId',
    model: UserModel,
  },
  render: ({ assignedToUserId, assignedToUser, userRoleId }) => {
    return (
      <UserOrUserRoleLink
        userId={assignedToUserId}
        user={assignedToUser}
        userRoleId={userRoleId}
      />
    );
  },
  renderString: async ({ assignedToUserId, assignedToUser, userRoleId }) => {
    return await renderUserOrUserRoleString({
      userRoleId,
      userId: assignedToUserId,
      user: assignedToUser,
    });
  },
});

export const createdByColumn = model.createColumn<{ createdByUser?: UserLabelFragment | null }>({
  title: 'Created By',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'createdByUserId',
    model: UserModel,
  },
  render: ({ createdByUser }) => UserModel.routes.renderRowLink(createdByUser),
});
