import { FeatureFlagKey_enum, PropertyFeatureFlag } from '../graphql/hasura/generated';

type Property = {
  propertyFeatureFlags: Pick<PropertyFeatureFlag, 'featureFlagKey'>[]
} | null | undefined;

export function hasPropertyFeatureFlag(property: Property, featureFlagKey: FeatureFlagKey_enum) {
  const propertyFeatureFlags = property?.propertyFeatureFlags || [];

  return propertyFeatureFlags.some(f => f.featureFlagKey === featureFlagKey);
}

export function isDpsProperty(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.DPS);
}

export function isEngrainMapEnabled(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.ENGRAIN_MAP_INTEGRATION);
}

export function isGatewayManagerProperty(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.GATEWAY_ACCESS_MANAGER);
}

export function isGatewayPinCodesEnabled(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.GATEWAY_PIN_CODES);
}

export function isHomeAccessEnabled(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.HOME_ACCESS);
}

export function isStratisProperty(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.COMMUNITY_CONNECT);
}

export function isVirtualDirectoryEnabled(property: Property) {
  return hasPropertyFeatureFlag(property, FeatureFlagKey_enum.VIRTUAL_DIRECTORY);
}
