import React from 'react';

import {
  PinCodeByPropertyFragment,
  PinCodeByPropertyFragmentDoc,
  PinCodeByUserFragment,
  PinCodeByUserFragmentDoc,
  PinCodeMainTableFragment,
  PinCodeMainTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as columns from './columns';
import model from './model';

const description = () => {
  return (
    <>
      These pin codes are used to gain access into the property only and will not work on the residential unit.
      Unit pin codes (if applicable) are created by the resident.
    </>
  );
};

export const PinCodesMainTable = model.createTable<PinCodeMainTableFragment>({
  description,
  fragment: PinCodeMainTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.codeColumn,
    columns.assignedToColumn,
    columns.createdByColumn,
    createdAtColumn,
  ],
});

export const PinCodesByPropertyTable = model.createTable<PinCodeByPropertyFragment, string>({
  description,
  fragment: PinCodeByPropertyFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    propertyId: { _eq: propertyId },
  }),
  columns: [
    columns.codeColumn,
    columns.assignedToColumn,
    columns.createdByColumn,
    createdAtColumn,
  ],
});

export const PinCodesByAssignedToUserTable = model.createTable<PinCodeByUserFragment, string>({
  description,
  fragment: PinCodeByUserFragmentDoc,
  fixedQueryFilters: (userId) => ({
    assignedToUserId: { _eq: userId },
  }),
  columns: [
    columns.propertyColumn,
    columns.codeColumn,
    columns.createdByColumn,
    createdAtColumn,
  ],
  useSubscription: true,
});
