import { Checkbox, Divider } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  RoleKey_enum,
  SmartLock_bool_exp,
  usegetPropertyDetailsQuery,
} from '../../graphql/hasura/generated';
import { SmartLockModel } from '../../models/SmartLock/model';
import { authentication } from '../../stores';
import { isGatewayPinCodesEnabled, isHomeAccessEnabled } from '../../utils';
import ModelFormSelect from '../ModelForm/ModelFormSelect';

import { IGrantAccessPageMergedProps } from './formik';

export const PinCodesSection: React.FC<IGrantAccessPageMergedProps> = (props) => {
  const { setFieldValue, values: formikValues, errors: formikErrors, isSubmitting } = props;
  const { activeUserTab, scopedIds, enablePropertyPinCode, smartLockIds } = formikValues;

  const propertyId = scopedIds[0];

  if (activeUserTab !== 'UNREGISTERED_USER') {
    return null;
  }

  const { data: propertyData } = usegetPropertyDetailsQuery({
    skip: !propertyId,
    variables: { propertyId },
    onCompleted: (data) => {
      if (isGatewayPinCodesEnabled(data.property)) {
        setFieldValue('enablePropertyPinCode', true);
      }
    },
  });

  const property = propertyData?.property;

  const canPropertyPinCodeBeManaged = (
    isGatewayPinCodesEnabled(property) &&
    authentication.hasPermission(PermissionKey_enum.PinCode_GenerateRandomByUser)
  );

  const canReadSmartLockRole = authentication.hasPermission(PermissionKey_enum.User_Read) ||
    authentication.currentRoleRelationships.some(r => (
      r.relatedRole.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER &&
      r.canReadUserRole
    ));

  const canGrantSmartLockRole = authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped) ||
    authentication.currentRoleRelationships.some(r => (
      r.relatedRole.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER &&
      r.canGrantUserRole
    ));

  const canSmartLockPinCodesBeManaged = (
    isHomeAccessEnabled(property) &&
    SmartLockModel.permissions.canRead() &&
    canReadSmartLockRole &&
    canGrantSmartLockRole
  );

  return (
    <>
      <Checkbox
        checked={enablePropertyPinCode}
        disabled={!canPropertyPinCodeBeManaged || isSubmitting}
        onChange={(e) => {
          setFieldValue('enablePropertyPinCode', e.target.checked);
        }}
        style={{ marginBottom: 20 }}
      >
        Enable pin code for property access on entries with keypads
      </Checkbox>
      <ModelFormSelect
        fieldName='smartLockIds'
        formItemProps={{
          className: 'label-align-top',
          style: { marginBottom: 40 },
        }}
        modelSelectProps={{
          style: { width: '100%' },
          model: SmartLockModel,
          value: smartLockIds,
          multiple: true,
          allowClear: true,
          disabled: !canSmartLockPinCodesBeManaged || isSubmitting,
          queryFilters: {
            unit: {
              propertyId: { _eq: propertyId },
            },
          } as SmartLock_bool_exp,
        }}
        setFieldValue={(fieldName, nextScopedIds) => {
          setFieldValue(fieldName, nextScopedIds);
        }}
        errors={formikErrors}
        submitCount={1}
      />
      {scopedIds.length > 0 && <Divider />}
    </>
  );
};

export default PinCodesSection;
