import { Form } from '@ant-design/compatible';
import { Alert, Button, Card, Divider, Radio, Steps, Typography } from 'antd';
import { withFormik } from 'formik';
import React from 'react';

import PhoneNumberField from '../../../components/PhoneNumberField';
import { MessageMethod } from '../../../graphql/api/generated';
import { FormItem, TextField } from '../../../pages/ModelForm/form-fields';
import { UserModel } from '../../User/model';

import {
  getInvitationAccess,
  handleSubmit,
  ISendInvitationFormProps,
  ISendInvitationFormValues,
  ISmartLockPinCodeFormMergedProps,
  mapPropsToValues,
  validate,
} from './send-invitation-formik';

const SendInvitationForm: React.FC<ISmartLockPinCodeFormMergedProps> = (props) => {
  const { userRole, values, setFieldValue, isSubmitting } = props;
  const { hasSmartSigns, hasPropertyPinCode, hasSmartLockPinCodes } = getInvitationAccess(userRole);
  const invitationHasAccess = hasSmartSigns || hasPropertyPinCode || hasSmartLockPinCodes;

  return (
    <Card
      title='Send Invitation'
      bordered={false}
      style={{ marginBottom: 20 }}
    >
      <Typography.Paragraph>
        The invitation will include the following information:
      </Typography.Paragraph>
      <Steps direction='vertical' size='small' className='send-invitation-steps'>
        <Steps.Step
          status='finish'
          title='Start/end date of access'
        />
        <Steps.Step
          status={hasSmartSigns ? 'finish' : 'error'}
          title='Guest Pass URL for property access'
        />
        <Steps.Step
          status={hasPropertyPinCode ? 'finish' : 'error'}
          title='Pin code for property access'
        />
        <Steps.Step
          status={hasSmartLockPinCodes ? 'finish' : 'error'}
          title='Pin code for unit access'
        />
      </Steps>
      <Divider />
      <Form onSubmit={props.handleSubmit}>
        <Radio.Group
          value={values.messageMethod}
          style={{ display: 'block', marginBottom: 20 }}
          onChange={e => {
            setFieldValue('messageMethod', e.target.value);
          }}
        >
          {[
            { label: 'Text Message', value: MessageMethod.SMS },
            { label: 'Email', value: MessageMethod.EMAIL },
          ].map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Radio.Group>
        {values.messageMethod === MessageMethod.SMS && (
          <FormItem {...props}
            formItemLayout={{}}
            isNew={false}
            field={UserModel.introspection.getField('phoneNumber')}
          >
            <PhoneNumberField
              value={values.phoneNumber}
              onChange={(value) => setFieldValue('phoneNumber', value)}
              disabled={props.isSubmitting}
            />
          </FormItem>
        )}
        {values.messageMethod === MessageMethod.EMAIL && (
          <TextField
            {...props}
            formItemLayout={{}}
            isNew
            required
            field={UserModel.introspection.getField('email')}
          />
        )}
        {values.invitationSent && !isSubmitting && (
          <Alert
            type='success'
            showIcon
            message='Invitation sent successfully!'
            style={{ marginBottom: 25 }}
          />
        )}
        <section style={{
          overflow: 'hidden',
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}>
          <Button
            htmlType='submit'
            type='primary'
            size='large'
            loading={isSubmitting}
            disabled={isSubmitting || !invitationHasAccess}
          >
            {isSubmitting ? 'Sending...' : 'Send Invitation'}
          </Button>
        </section>
      </Form>
    </Card>
  );
};

export default withFormik<ISendInvitationFormProps, ISendInvitationFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(SendInvitationForm);
