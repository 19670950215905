import { Steps, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PermissionScope_enum,
  RoleKey_enum,
  usegetUserRoleDetailsSubscription,
  UserRoleDetailsFragment,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { IModelColumn } from '../../typings';
import UserRolePinCodeManager from '../components/UserRolePinCodeManager';
import {
  AccessPointUserRolesTable,
  GlobalUserRolesTable,
  OrganizationUserRolesTable,
  PropertyUserRolesTable,
  SmartLockUserRolesTable,
  UnitUserRolesTable,
} from '../tables';
import { expandable } from '../tables/common';

const UserRoleDetailsLayout = createDetailsLayout<UserRoleDetailsFragment>();

export const UserRoleDetailsPage: React.FC<any> = () => {
  return (
    <UserRoleDetailsLayout
      modelName='UserRole'
      useQuery={(userRoleId) => {
        return usegetUserRoleDetailsSubscription({
          variables: { userRoleId },
        });
      }}
      body={(userRole) => {
        let columns: IModelColumn<any>[] = [];

        switch (userRole.role.permissionScope) {
          case PermissionScope_enum.GLOBAL:
            columns = GlobalUserRolesTable.columns;
            break;
          case PermissionScope_enum.ORGANIZATION:
            columns = OrganizationUserRolesTable.columns;
            break;
          case PermissionScope_enum.PROPERTY:
            columns = PropertyUserRolesTable.columns;
            break;
          case PermissionScope_enum.UNIT:
            columns = UnitUserRolesTable.columns;
            break;
          case PermissionScope_enum.ACCESS_POINT:
            columns = AccessPointUserRolesTable.columns;
            break;
          case PermissionScope_enum.SMART_LOCK:
            columns = SmartLockUserRolesTable.columns;
            break;
          default:
            return null;
        }

        const normalizedColumns = normalizeColumns(columns);
        const { role, parentUserRoleId } = userRole;
        const propertyPinCode = userRole.pinCodes[0];

        return (
          <>
            <Table
              columns={normalizedColumns}
              dataSource={[userRole]}
              rowKey={r => r.userRoleId}
              pagination={false}
              expandable={expandable}
            />
            {role.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER && parentUserRoleId && (
              <div style={{ marginTop: 25 }}>
                <Link to={`/assigned-roles/details/${parentUserRoleId}`}>
                  View Guest Pass
                </Link>
              </div>
            )}
            {propertyPinCode && (
              <Steps
                size='small'
                style={{ marginTop: 25 }}
              >
                <Steps.Step
                  status={'finish'}
                  title='Pin code enabled for property access on entries with keypads'
                />
              </Steps>
            )}
          </>
        );
      }}
      footer={(userRole) => (
        <UserRolePinCodeManager userRole={userRole} />
      )}
    />
  );
};

export default UserRoleDetailsPage;
